<template>
    <div class="application-container">
        <div
          v-if="!isError"
          class="application"
        >
          <Header
              class="application__header"
          ></Header>
            <Progress class="application__progress"/>
          <div class="application__content">
              <div class="application__wrapper">
                <router-view/>
              </div>
          </div>
          <Footer/>
        </div>
        <Error v-else/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from '@/components/common/header/Header'
import Error from '@/components/application/error/Error'
import Progress from '@/components/application/progress/Progress'
import './application.scss'

export default {
  name: 'ApplicationLayout',
    computed: {
      ...mapGetters({
          isError: 'error/isError'
      }),
    },
  components: {
      Header,
      Footer: () => import('@index/components/common/footer/Footer'),
      Error,
      Progress
  }
}
</script>

<style>
.application-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>
