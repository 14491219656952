<template>
    <div>
        <AggrementPopup
            v-if="showAggrement"
            @close="showAggrement = false"
        />
      <form
          @submit.prevent="validate"
          novalidate
          class="contact-information-view"
      >
          <div class="contact-information-view__calc">
              <div
                  class="contact-information-view__sum-info"
              >
                  <span class="contact-information-view__sum" v-html="sum"></span>
                  <span class="contact-information-view__sum-title" v-if="!this.isSbg">{{ title }}:</span>
              </div>
            <Calculator
              v-model="calculator"
              :showRangeInfo="3"
            />
              <Days
                  v-if="isSbg"
                  v-model="days"
                  class="contact-information-view__days"
              />
          </div>
        <Title
          class="contact-information-view__title"
        >
            Подать заявку
        </Title>
          <div class="contact-information-view__form">
            <ContactInformation
                ref="contactInformation"
              class="contact-information-view__form"
            />
            <div
                v-if="!isSbg || (isSbg && isAuth)"
                class="contact-information-view__agreement"
            >
              <Checkbox
                  :error="isSubmit && !agreement"
                  v-model="agreement"
              >
                  <div v-html="acceptanceText"></div>
              </Checkbox>
              <Checkbox
                  v-if="autoPaymentText"
                  :error="isSubmit && !autoPayment"
                  v-model="autoPayment"
              >
                  <div v-html="autoPaymentText"></div>
              </Checkbox>
          </div>
              <div
                  v-else
                  class="contact-information-view__agreement"
              >
                  <Checkbox
                      :error="isSubmit && !agreement"
                      v-model="agreement"
                  >
                      Согласен со <a href="#aggrement" @click.prevent="showAggrement = true">следующим</a>
                  </Checkbox>
              </div>
            <div
              class="contact-information-view__actions"
            >
              <Button
                type="submit"
              >
                Продолжить
              </Button>
            </div>
              <div class="contact-information-view__created" v-if="isAnticharge">
                  Дата создания: {{ $store.state.application.createdAt }}
              </div>
          </div>
      </form>
    </div>
</template>

<script>
import anticharge from '@/api/anticharge'
import store from '@index/store'

import sbg from '@index/mixins/sbg'
import Cookies from 'js-cookie'

import './contact-information-view.scss'

import price from '@/helpers/string/price';

import Title from '@index/components/gui/title/Title'
import ContactInformation from '@index/components/application/contact-information/ContactInformation'
import Checkbox from '@index/components/common/checkbox/Checkbox'
import Button from '@index/components/gui/button/Button'
import term from '@/helpers/string/term'

export default {
  name: 'ContactInformationView',
    mixins: [
        sbg
    ],
  data() {
    return {
      calculator: 10000,
        agreement: false,
        autoPayment: false,
        isSubmit: false,
        days: {
            type: 'days',
            count: 10
        },
        showAggrement: false
    }
  },
    computed: {
        isAnticharge() {
            return this.$route.name === 'Anticharge'
        },
      sum() {
          const sum = [`${price(this.calculator)} ₽`]

          if (this.isSbg) {
              sum.push(`<span>на ${this.days.count} ${this.loanDaysString}</span>`)
          }

          return sum.join(' ')
      },
        loanDaysString() {
            if (this.days.type === 'days') {
                return term(this.days.count)
            }

            return 'недель'
        },
        title() {
            return this.$DICTIONARY.calculatorTitle
        },
      acceptanceText() {
          if (!Cookies.get('sbg-in'))
              return this.$DICTIONARY.acceptanceTextFirst || this.$DICTIONARY.acceptanceText

          return this.$DICTIONARY.acceptanceText
      },
        autoPaymentText() {
            return this.$DICTIONARY.acceptanceAutoPayment
        }
    },
    created() {
        this.fieldFocusEvent = this.fieldFocusEvent.bind(this)
      this.autoPayment = this.agreement = this.isSbg
    },
    mounted() {
        const phoneWatcher = this.$watch('$refs.contactInformation.form.phone', (phone) => {
            if (phone.length === 16 && this.agreement) {
                this.submit(false)
                phoneWatcher()
                agreementWatcher()
            }
        })

        const agreementWatcher = this.$watch('agreement', (agreement) => {
            if (this.$refs.contactInformation.form.phone.length === 16 && agreement) {
                this.submit(false)
                phoneWatcher()
                agreementWatcher()
            }
        })

        if (!this.isSbg)
            return;

        setTimeout(() => {
            document.querySelectorAll('input').forEach(field => {
                field.addEventListener('input', this.fieldFocusEvent)
            })
        })
    },
    methods: {
        fieldFocusEvent() {
            Cookies.set('notInterested', '0')
            window.onscroll = null;
        },
      validate() {
          this.isSubmit = true
          this.$refs.contactInformation.validate();

          ((this.autoPaymentText && this.autoPayment) || !this.autoPaymentText) &&
          this.$refs.contactInformation.isValid && this.agreement && this.submit()
      },
      async submit(send = true) {
          const { checkPhoneByCode, noValid } = await this.$store.dispatch('application/send', {
              contactData: this.$refs.contactInformation.form,
              showLoader: send
          })

          if (noValid && Object.keys(noValid)) {
              const formErrors = this.$refs.contactInformation.formErrors

              formErrors.phone = (noValid.phone === false && 'Невалидное значение') || ''
              formErrors.email = (noValid.email === false && 'Невалидное значение') || ''

              return;
          }

          if (!send)
              return;

          if (!checkPhoneByCode) {
              this.$router.push({ name: 'PersonalInfo' })
          } else {
              this.$router.push({ name: 'Auth' })
          }
      }
    },
  components: {
    Title,
    ContactInformation,
    Checkbox,
    Button,
    Calculator: () => import('@index/components/common/calculator/Calculator'),
      Days: () => import('@/components/common/days/Days'),
      AggrementPopup: () => import('./AgreementPopup')
  },
    async beforeRouteEnter(to, from, next) {
      if (to.name === 'Anticharge') {
          await anticharge(to.query)
          await store.dispatch('application/update')
      }

      store.commit('application/load', false)
      next()
    }
};
</script>
